import React from 'react';

export default function Loader() {
    return (
        <div className='d-flex justify-content-center align-items-center flex-column' style={{ position: "fixed", top: 0, zIndex: 1000, width: "100%", height: "100vh", backgroundColor: "#ffffff75" }}>
            <div className="spinner-border" style={{ width: "4rem", height: "4rem" }} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <h5 className='mt-3 fw-bold'>Please Wait...</h5>
        </div>
    );
}


