import React, { useEffect, useState } from 'react'
import moment from 'moment';
// import Loader from '../Loader/Loader';
import Loader from '../Loader';

import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { Radio } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// import OrderDilog from '../Loader/OrderDilog';
import OrderDilog from '../OrderDialog';

export default function NewThankyou() {


  const [open, setopen] = useState(true);
  // console.log(open);
  const [Orderid, setOrderid] = useState("");
  const [success, setsuccess] = useState(false);

  const [data, setdata] = useState({});
  const [isonline, setisonline] = useState(true);
  const [validationErr, setvalidationErr] = useState("");


  const Location = useNavigate()
  if (window.location.pathname !== "/") {
    import("./new-thankyou.css")
  }

  const { enqueueSnackbar: showSnackbar } = useSnackbar();

  const [err, seterr] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [AllProducts, setAllProducts] = useState([]);


  const [Customer_data, setCustomer_data] = useState({
    name: window.location.search ? decodeURI(window.location.search.split("name=")[1].split("&phone=")[0]) : "",
    phone: window.location.search ? decodeURI(window.location.search.split("phone=")[1].split("&address=")[0]) : "",
    address: window.location.search ? decodeURI(window.location.search.split("address=")[1]) : "",
    state: "",
    city: "",
    pincode: "",
    email: "",
    postOffice: ""
  })

  // console.log(Customer_data);

  // function gtag_report_conversion(url) {
  //   var callback = function () {
  //     if (typeof (url) != 'undefined') {
  //       Location(url);
  //     }
  //   };
  //   window.gtag('event', 'conversion', {
  //     'send_to': window.conversion_code,
  //     'event_callback': callback
  //   });
  //   return false;
  // }
  const PostonClick = async (e) => {

    e.preventDefault();

    let adddata = {
      customer_name: Customer_data.name,
      customer_phone: Customer_data.phone,
      customer_email: Customer_data.email,
      customer_address: Customer_data.address,
      pincode: Customer_data.pincode,
      state: Customer_data.state,
      city: Customer_data.city,
      postOffice: null,
      product: data.id,
      product_name: data.name,
      price: data.price,
      discount: data.discount,
      totalPrice: data.price - data.discount,
      payment_mode: isonline ? "online" : "cod",
      form_id: null
    }

    if (adddata.customer_name === "" || `${adddata.customer_name}` === "null" || typeof adddata.customer_name === "undefined") {
      return setvalidationErr("Required Field Name Is Empty");
    }
    setvalidationErr();

    if (adddata.customer_phone === "" || `${adddata.customer_phone}` === "null" || typeof adddata.customer_phone === "undefined") {
      return setvalidationErr(`Required Field Phone Is Empty`)
    }
    setvalidationErr("")
    if (adddata.customer_phone.length !== 10) {
      return setvalidationErr("Please Enter valid Phone Number")
    }
    setvalidationErr("")
    if (adddata.customer_phone[0] !== "9" && adddata.customer_phone[0] !== "8" && adddata.customer_phone[0] !== "7" && adddata.customer_phone[0] !== "6") {
      return setvalidationErr("Please Enter a Valide Phone Number")
    }
    setvalidationErr("")
    if (!/^\d+$/.test(adddata.customer_phone)) {
      return setvalidationErr("Please Enter a Valide Phone Number")
    }
    setvalidationErr("")
    // if (adddata.customer_phone[0] === "0" || adddata.customer_phone[0] === "1" || adddata.customer_phone[0] === "2" || adddata.customer_phone[0] === "3" || data.customer_phone[0] === "4" || data.customer_phone[0] === "5") {
    //     return setvalidationErr(`Your Phone Number Is Invalide Please Chake Your Phone Number`, { variant: "error" })
    // }
    setvalidationErr("")
    if (adddata.customer_address === "" || `${adddata.customer_address}` === "null" || typeof adddata.customer_address === "undefined") {
      return setvalidationErr(`Required Field Address Is Empty`)
    }
    setvalidationErr("")
    if (adddata.customer_email === "" || `${adddata.customer_email}` === "null" || typeof adddata.customer_email === "undefined") {
      return setvalidationErr(`Required Field Email Is Empty`)
    }
    setvalidationErr("")
    if (adddata.pincode === "" || `${adddata.pincode}` === "null" || typeof adddata.pincode === "undefined") {
      return setvalidationErr(`Required Field Postal-code Is Empty`)
    }
    setvalidationErr("")
    if (adddata.state === "" || `${adddata.state}` === "null" || typeof adddata.state === "undefined") {
      return setvalidationErr(`Please Enter Valide Postal-code`)
    }
    setvalidationErr("")
    if (adddata.city === "" || `${adddata.city}` === "null" || typeof adddata.city === "undefined") {
      return setvalidationErr(`Please Enter Valide Postal-code`)
    }
    setvalidationErr("")


    try {

      setLoading(true)

      const Result = await axios.post(`${process.env.REACT_APP_API_URL}order/create?api_key=${process.env.REACT_APP_API_KEY}`, adddata, { headers: { "web_site_url": process.env.REACT_APP_URL } })
      if (Result.status === 200) {
        setLoading(false)
        showSnackbar("Order Created Successfully", { variant: "success" })
        if (adddata.payment_mode === "online") {
          Result.data.data["customer_name"] = adddata.customer_name
          Result.data.data["customer_email"] = adddata.customer_email
          Result.data.data["customer_phone"] = adddata.customer_phone
          setOrderid(Result.data.OrderId)
          initPayment(Result.data.data, Result.data.OrderId)
        } else {
          setsuccess(true)
          setOrderid(Result.data.OrderId)
        }
      } else {
        setLoading(false)
        return showSnackbar("Faild To Create Order", { variant: 'error' })
      }
      localStorage.removeItem("name")
      localStorage.removeItem("phone")
      localStorage.removeItem("address")
    } catch (err) {
      localStorage.removeItem("name")
      localStorage.removeItem("phone")
      localStorage.removeItem("address")
      setLoading(false)
      return showSnackbar("Faild To Create Order", { variant: "error" })
    }
  }


  const initPayment = (raz, OrderId) => {
    setLoading(true);
    const options = {
      key: raz.razorpay_key_id,
      amount: raz.amount,
      name: raz.product_name,
      description: "Test Transaction",
      image: data.img,
      order_id: raz.id,
      notes: { "merchant_order_id": "SGI-" + OrderId },
      prefill: {
        name: raz.customer_name,
        email: raz.customer_email,
        contact: raz.customer_phone
      }, handler: async (response) => {
        response["receipt"] = raz.receipt
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}order/verify?api_key=${process.env.REACT_APP_API_KEY}`, response, { headers: { "web_site_url": process.env.REACT_APP_URL } })
          if (data.message === "Payment verified successfully") {
            setLoading(false)
            showSnackbar("Payment verified Success", { variant: "success" })
            setsuccess(true)
          } else {
            setLoading(false)
            showSnackbar("Payment Varification Failed", { variant: "error" })
          }
        } catch (err) {
          setLoading(false)
          showSnackbar("Payment Varification Failed", { variant: "error" })
        }
      }, theme: {
        color: "#3399cc"
      }
    }
    const rzp1 = new window.Razorpay(options)
    rzp1.on('payment.failed', function (response) {
      options.handler(response)
    });
    rzp1.open()
    setLoading(false)
  }


  const [WindowWidth, setWindowWidth] = useState(window.innerWidth);
  const [Reviews, setReviews] = useState([]);

  const Url = window.location.origin;
  // console.log(Url);


  const FetchProduct = async () => {
    try {
      setLoading(true)
      const result = await axios.get(`${process.env.REACT_APP_API_URL}product/fetch_with_api_key?api_key=${process.env.REACT_APP_API_KEY}`, { headers: { "web_site_url": process.env.REACT_APP_URL } })
      if (result.status === 200) {
        setLoading(false)
        document.title = result.data.product[0].name
        setAllProducts(result.data.product)

        return setdata(result.data ? result.data.product ? result.data.product.length > 0 ? { ...result.data.product[0], img: Url + "/media/checkout/product2.png" } : {} : {} : {})

      }
      setLoading(false)
      showSnackbar("Failed to Fetch Reviews", { variant: "error" })
    } catch (err) {
      setLoading(false)
      return showSnackbar(err.response && err.response.data ? err.response.data.message : err.message, { variant: "error" })
    }
  }

  const FetchProductReviews = async () => {
    let proids = AllProducts.map((x) => x.id)
    proids = proids.join(",")
    try {
      setLoading(true)
      let Reviews = await axios.get(`${process.env.REACT_APP_API_URL}reviews/proid`, {
        headers: {
          "proid": proids
        }
      })
      setLoading(false)
      if (Reviews.status === 200) {
        setReviews(Reviews.data.reviews)
      }
    } catch (err) {
      setLoading(false)
      showSnackbar("Reviews Not Found", { variant: "info" })
    }
  }

  useEffect(() => {
    FetchProduct()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (AllProducts.length > 0) {
      FetchProductReviews()
    }
    // eslint-disable-next-line
  }, [AllProducts]);


  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
    // eslint-disable-next-line
  }, [WindowWidth]);



  let [view, setview] = useState(21547);
  const [random, setrandom] = useState(0);

  let rendome = Math.floor(Math.random() * 10)
  // console.log(rendome);
  useEffect(() => {
    setTimeout(() => {
      setrandom(rendome);
      // eslint-disable-next-line
      setview(view += random);

    }, 1500);

  },)
  const [seconds, setseconds] = useState(59)
  const [minuts, setminuts] = useState(59)
  const [hour, sethour] = useState(0)

  // useEffect(() => {
  //     let Timer = setInterval(() => {
  //         setseconds(seconds - 1)
  //         if (seconds === 0) {
  //             setminuts(minuts - 1)
  //             setseconds(60)
  //         } if (minuts === 0) {
  //             sethour(hour + 1)
  //             setminuts(59)
  //             setseconds(60)
  //         }
  //         // eslint-disable-next-line 
  //     }, 1000)
  //     return () => {
  //         clearInterval(Timer)
  //     }
  // })

  setTimeout(() => {
    setseconds(seconds - 1)
    if (seconds === 0) {
      setminuts(minuts - 1)
      setseconds(59)
    } if (minuts === 0) {
      sethour(hour + 1)
      setminuts(59)
      setseconds(59)
    }
  }, 1000);


  const GetPostOffice = async (e) => {
    let postalcode = e.target.value
    if (postalcode.length === 6) {
      if (`${Number(postalcode)}` === "NaN") {
        return showSnackbar("Invalide Postal Code", { variant: "warning" })
      }
    }
    try {
      if (postalcode.length === 6) {
        setLoading(true)
        const data = await axios.get(`https://api.postalpincode.in/pincode/${postalcode}`,)
        if (data.status === 200) {
          setLoading(false)
          if (data.data[0].Status === "Success") {
            setCustomer_data({ ...Customer_data, pincode: e.target.value, state: data.data[0].PostOffice[0].State, city: data.data[0].PostOffice[0].District })
          } else {
            showSnackbar("No Records Found", { variant: "error" })
          }
        } else {
          setLoading(false)
          return showSnackbar("Failed To Fetch Post Office", { variant: "error" })
        }
      } else {
        setCustomer_data({ ...Customer_data, state: "", city: "" })
      }
    } catch (err) {
      setLoading(false)
      return showSnackbar("Failed To Fetch Post Office", { variant: "error" })
    }
  }


  // let Time = new Date()
  // Time = Time.getHours()

  const [number, setnumber] = useState(23037)

  useEffect(() => {

    setTimeout(() => {
      setnumber(number + 4)
      if (number > 25000) {
        setnumber(23032)
      }

    }, 1600);

  }, [number])


  // console.log(data);

  window.onscroll = function () { scrollFunction() };

  function scrollFunction() {

    // console.log(window.innerWidth)

    if (document.body.scrollTop > 550 || document.documentElement.scrollTop > 550) {
      document.getElementById("bicc").style.bottom = "0%";
      document.getElementById("bicc").style.transitionDuration = "1s";
    } else {
      document.getElementById("bicc").style.bottom = "-16%";
    }
  }

  return (
    <>
      {
        Loading ? (
          <Loader />
        ) : ""
      }



      <div>

        <OrderDilog open={success} setOpen={setsuccess} Orderid={Orderid} />

        <div style={{ background: 'rgb(14,7,3)' }} >
          <div className="container">
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-start align-items-center my-3'>
                <div className='' style={{ width: "17%" }}>
                  <img src="/media/checkout/logo1.png" alt="" className='w-100' />
                </div>
                <div className='text-center ms-2'>
                  <div className='fw-bold fs-5' style={{ color: "rgb(255,177,0)" }}>Knight Rider Tablet</div>
                  <small className='text-light'>THE GOOD MAN SECRET</small>
                </div>
              </div>

              <div className='text-light'>
                <i className='fa-solid fa-bars fs-1'></i>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className={`mt-4 text-center text-light ${WindowWidth > 768 ? "" : "d-flex justify-content-center"}`}>
              <h1 className='text-center'><span>Knight Rider Tablet-  </span><span className={`world-s ${WindowWidth > 768 ? "" : "d-block"}`}>World's Strongest</span> Ayurvedic Herbs Extract</h1>
            </div>

            <p className='fs-5 text-light px-2 text-center mt-4'>
              Now perform better and naturally with Knight Rider Tablet . it is scientifically proven to improve your testosterone levels also boost your strength & stamina & libdo.
            </p>

            <div className="row">
              <div className='mt-3'>
                <h5>
                  <i className="fa-solid fa-star text-danger"></i> &nbsp;
                  <span className='text-light fw-bold'>
                    Additional Offer
                  </span>
                </h5>
                <h6>
                  <SendIcon className='fs-6 text-danger' /> &nbsp;
                  <span className='text-light'>
                    Get 10% extra discount on online payment
                  </span>
                </h6>
                <h6>
                  <SendIcon className='fs-6 text-danger' /> &nbsp;
                  <span className='text-light'>
                    COD Available
                  </span>
                </h6>
              </div>
            </div>

            {/* <div className='text-light' style={{ position: "fixed" }} id="bicc">xyz</div> */}


            <div className='text-center text-light mb-4'>
              <h4 className='p-2 bg-dark d-inline-block px-3 py-3 back mt-4'>Buy Knight Rider Tablet Now &gt;</h4>
            </div>

            <div className='text-center' style={{ color: "#ffee9b" }}>
              <h4 className='px-4 py-4 mb-0'>10 Lakhs+ Satisfied Customer trust Knight Rider Tablet</h4>
            </div>
          </div>
        </div>

        <div className={`${WindowWidth > 768 ? "container" : ""}`} style={{ background: "rgb(14,7,3)" }}>
          <div className=''>
            {/* <div className={`d-flex py-4  m-auto   justify-content-center align-items-center gap-1`} style={{ width: WindowWidth > 768 ? "60%" : "100%" }} >


                          <div className='col-6 text-center' style={{ background: "rgb(185 216 223)", borderRadius: "13px", width: "47%" }}>
                              <div className='w-100 px-1'>
                                  <img src="/media/checkout/product2.png" alt="" className='w-100' />
                              </div>
                              <div className='fw-bold text-center'>60 + 60 Tablets</div>
                              <p className='fw-bold mb-0'>Old Price : <strike style={{ color: "red" }}>7000Rs.</strike></p>
                              <p className='fw-bold'>New Price : <span style={{ color: "red" }}>3000Rs.</span></p>

                          </div>

                          <div className='text-center' style={{ background: "rgb(185 216 223)", borderRadius: "13px", width: "47%" }}>
                              <div>
                                  <div className='w-100 px-1'>
                                      <img src="/media/checkout/product1.png" alt="" className='w-100' />
                                  </div>
                                  <div className='fw-bold fs-6 text-center'>60 Tablets </div>
                                  <p className='fw-bold mb-0'>Old Price : <strike style={{ color: "red" }}>3500Rs.</strike></p>
                                  <p className='fw-bold'>New Price :<span style={{ color: "red" }}> 2000Rs.</span></p>
                              </div>
                          </div>

                      </div> */}

            <div className='d-flex m-auto gap-1 justify-content-center align-items-center py-3' style={{ width: WindowWidth > 768 ? "90%" : "100%" }} >
              {

                AllProducts ? AllProducts.length > 0 ?

                  AllProducts.map((e, key) => {

                    return <label className={`text-center ${data.id === e.id ? "buy" : "cv"}`} htmlFor={e.id} style={{ borderRadius: "13px", width: "47%", cursor: "pointer" }} key={key}>
                      <Radio hidden
                        onChange={(x) => {

                          let price = data.price;

                          setdata({ ...e, img: key === 0 ? Url + '/media/checkout/product2.png' : Url + '/media/checkout/product1.png', discount: isonline ? e.discount : 0 });
                        }}
                        checked={data.id === e.id}
                        id={`${e.id}`}
                      />
                      <div>
                        <div className='w-100 px-1 pt-3 pb-2'>
                          <img src={AllProducts ? key === 0 ? "/media/checkout/product2.png" : "/media/checkout/product1.png" : ""} alt="" className={WindowWidth > 768 ? "w-75" : "w-100"} />
                        </div>
                        <div className='fw-bold fs-5 text-center'>{e.short_content}</div>
                        {/* <p className='fw-bold fs-5 mt-3'>2000</p> */}
                        <p className='fw-bold mb-0'>Old Price : <span style={{ textDecoration: "line-through", }}>{e.reguler_price}Rs</span></p>
                        <p className='fw-bold'>New Price :<span>{e.price}Rs</span></p>
                      </div>
                    </label>
                  })
                  : "" : ""
              }
            </div>
          </div>

          <div className='w-100 my-3'>

            <h4 className='text-light ms-3'>Choose Payment Mode</h4>
            <hr className='text-light' />

            <div className='d-flex align-items-baseline'>
              <Radio
                checked={isonline}
                id="online"
                onChange={

                  () => {
                    setisonline(true);
                    let price = data.price;
                    setdata({ ...data, discount: price * 10 / 100 })


                  }

                }
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                className="text-light"
              />
              <label htmlFor="online" className='text-light'><div>CREDIT CARD / DEBIT CARD / NETBANKING</div>
                <small className='text-danger'>Extra 10% Discount</small>

              </label>

            </div>
            <div className='d-flex align-items-center'>
              <Radio
                checked={!isonline}
                id="cash"
                onChange={

                  () => {
                    setisonline(false);
                    setdata({ ...data, discount: 0 });
                  }


                }
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                className="text-light"
              />

              <label htmlFor="cash" className='text-light'>CASH ON DELIVERY</label>
            </div>
          </div>
          {/* <div className={`m-auto ${WindowWidth > 768 ? "w-50" : "w-100"}`}>
            <div className='d-flex justify-content-center align-items-center'>
              // <div className='fs-4 px-4 py-2 border border-1 '>1</div> 

              <div className='w-100'>
                <div type="button" id="form" className='px-2 mx-2 text-center fs-4  border border-1 py-2 text-light buy' onClick={() => {
                  // Location(`/thankyou?id=${data.id}`)
                  setopen(false);
                }
                } style={{ cursor: "pointer", borderRadius: "13px" }}>BUY NOW</div>
              </div>
            </div>
          </div> */}

          {/* 
                  <div className='text-center py-3'>
                      <div type='button' className='bg-light d-inline-flex px-4 py-2 fw-bold'

                          onClick={() => {
                              setopen(false);
                          }}
                      >Buynow</div>
                  </div> */}

          <hr className="border" />

          <div className="container">

            <form onSubmit={PostonClick} id='form'>
              <div className=''>

                <div className='row text-light  py-3' style={{ borderRadius: "20px" }}>
                  <div className='col-12 col-sm-6 col-md-4 m-auto'>
                    <div className='m-auto input_size'>
                      <label htmlFor="" className='fw-bold fs-5 d-block text-center'>Name</label>
                      <input type="text" className='m-auto w-100 py-2 rounded-3 input_color input_border' value={Customer_data.name} placeholder='नाम लिखिए..'
                        onChange={(e) => {
                          setCustomer_data({ ...Customer_data, name: e.target.value })
                        }}
                      />

                      <div className='text-danger py-1'>
                        {
                          validationErr.includes("Name") ? validationErr : ""
                        }

                      </div>

                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-4 m-auto'>
                    <div className='input_size m-auto'>
                      <label htmlFor="" className='fw-bold fs-5 d-block text-center'>Email</label>
                      <input type="email" className='m-auto w-100 py-2 rounded-3 input_color input_border' placeholder='ईमेल लिखिए ...' onChange={(e) => {

                        setCustomer_data({ ...Customer_data, email: e.target.value });


                      }} />
                      {/* <div className='text-danger py-1'>
                                              {
                                                  validationErr.includes("Email") ? validationErr : ""
                                              }

                                          </div> */}



                    </div>



                  </div>
                  <div className='col-12 col-sm-6 col-md-4 my-1'>
                    <div className='input_size m-auto'>
                      <label htmlFor="" className='fw-bold fs-5 d-block text-center'>Phone</label>
                      <input type="text" maxLength={10} minLength={10} className='m-auto w-100 py-2 rounded-3 input_color input_border' placeholder='फ़ोन नंबर लिखिए ...'
                        value={Customer_data.phone}
                        onChange={(e) => {
                          setCustomer_data({ ...Customer_data, phone: e.target.value })

                        }}

                      />

                      <div className='text-danger py-1'>
                        {
                          validationErr.includes("Phone") ? validationErr : ""
                        }

                      </div>

                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-4 my-1'>
                    <div className='input_size m-auto'>
                      <label htmlFor="" className='fw-bold fs-5 d-block text-center'>Pincode</label>
                      <input type="text" className='m-auto w-100 py-2 rounded-3 input_color input_border' placeholder='पिनकोड लिखिए ..' maxLength={6} onChange={GetPostOffice} />
                      <div className='text-danger py-1'>
                        {
                          validationErr.includes("Pincode") ? validationErr : ""
                        }

                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-sm-6 col-md-4 my-1'>
                    <div className='input_size m-auto'>
                      <label htmlFor="" className='fw-bold fs-5 d-block text-center'>State</label>
                      <input type="text" className='m-auto w-100 py-2 rounded-3 input_color input_border' disabled placeholder='स्टेट का नाम  लिखिए ..' value={Customer_data.state} />

                      <div className='text-danger py-1'>
                        {
                          validationErr.includes("State") ? validationErr : ""
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-4 my-1'>
                    <div className='input_size m-auto'>
                      <label htmlFor="" className='fw-bold fs-5 d-block text-center'>City</label>
                      <input type="text" className='m-auto w-100 py-2 rounded-3 input_color input_border' disabled placeholder='शहर / गांव का  नाम लिखिए..' value={Customer_data.city} />
                      <div className='text-danger py-1'>
                        {
                          validationErr.includes("City") ? validationErr : ""
                        }

                      </div>
                    </div>
                  </div>

                  <div className='col-12 my-1 px-4 w-100'>

                    <div className='w-100'>
                      <label htmlFor="" className="fw-bold fs-5 d-block text-center">Address</label>
                      <input type="textarea" className='input_color w-100 py-3 input_border' placeholder='एड्रेस  लिखिए' value={Customer_data.address} onChange={
                        (e) => {
                          setCustomer_data({ ...Customer_data, address: e.target.value });
                        }
                      } />
                      <div className='text-danger py-1'>
                        {
                          validationErr.includes("Address") ? validationErr : ""
                        }
                      </div>

                    </div>

                  </div>

                </div>
              </div>



              <hr className='text-light' />

              <div className=''>
                <div className='d-flex text-light justify-content-between align-items-center fs-5'>
                  <p className=''>Subtotal</p>
                  <p>{data.price} Rs.</p>
                </div>

                <div className='d-flex text-light justify-content-between align-items-center'>
                  <p>10% Discount on online payment</p>
                  <p>- {data.discount} Rs.</p>
                </div>
                <hr className='text-light' />
                <div className='d-flex text-light justify-content-between align-items-center fs-5'>
                  <p>Total</p>
                  <p>{data.price - data.discount} Rs.</p>
                </div>

              </div>







              <div className='text-center'>
                <input type="submit" value="Buy Now" className="rounded-3 border-0 px-4 py-1 fw-bold fs-4 mt-4 buy" />
              </div>



            </form>


          </div>









        </div>



        <center className="pt-3" >


          <div className='text-light'>
            <h3 className='py-3 mt-4 border-bottom border-top'>CELEBRITY SPEAKS</h3>

          </div>

          {
            WindowWidth > 768 ? (
              <>
                <iframe className='mb-2' width="50%" height="400px" src="https://www.youtube.com/embed/0HnqZUQAPH8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe className='mb-2' width="50%" height="400px" src="https://www.youtube.com/embed/GFX4hi4FUGc" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />

                <iframe className='mb-2' width="50%" height="400px" src="https://www.youtube.com/embed/nIQvZln9TyU" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
              </>
            ) : (
              <>
                <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/0HnqZUQAPH8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/GFX4hi4FUGc" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />

                <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/nIQvZln9TyU" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
              </>
            )
          }



          <div className='text-light'>
            <h3 className='py-3 mt-4 border-bottom border-top'>CUSTOMER SPEAKS</h3>

          </div>

          {
            WindowWidth > 768 ? (
              <>
                <iframe className='mb-2' width="50%" height="400px" src="https://www.youtube.com/embed/0ttJ6dF5Y-Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe className='mb-2' width="50%" height="400px" src="https://www.youtube.com/embed/qSZnFXTdRUM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe width="50%" height="400px" src="https://www.youtube.com/embed/mdS8lNKyFSI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe width="50%" height="400px" src="https://www.youtube.com/embed/nFGwnIG289o" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe width="50%" height="400px" src="https://www.youtube.com/embed/gatNpqoX1mA" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />

              </>
            ) : (
              <>
                <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/0ttJ6dF5Y-Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe className='mb-2' width="100%" height="200px" src="https://www.youtube.com/embed/qSZnFXTdRUM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe width="100%" height="200px" src="https://www.youtube.com/embed/mdS8lNKyFSI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe width="100%" height="200px" src="https://www.youtube.com/embed/nFGwnIG289o" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />
                <iframe width="100%" height="200px" src="https://www.youtube.com/embed/gatNpqoX1mA" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <br />

              </>
            )
          }




          <div className='text-light'>
            <h3 className='px-5 py-3 mt-4 d-inline-flex '>Customer Review</h3>
            <div className='border-bottom border-2 mb-2'></div>
          </div>

          <div>
            {
              Reviews ? Reviews.length > 0 ?

                Reviews.map((e, key) => {

                  return <div key={key} id='reviewWidth'>

                    <div className='d-flex  align-items-center my-3' style={{ marginLeft: ((WindowWidth - 275) / 2) }}>
                      <div className=''>
                        <img src={e.profile_photo} alt="" className='img-w' style={{ borderRadius: "50%" }} />
                      </div>
                      <div>
                        <div className='ms-2 d-flex align-items-center reviewContent'>
                          <p className='text-light align-items-center fw-bold fs-5 mb-0 me-2'>{e.customer_name}</p>
                          <img className='reviewImage' src="/media/checkout/verify.svg" alt="" width="23rem" />
                        </div>
                        <div>
                          <div className='text-light'>  {moment(e.created_at).format("DD MMM YYYY")}</div>
                        </div>
                      </div>
                    </div>
                    <div className='border-bottom border-bottom-3 pb-4 px-2 text-light fs-5'><p style={{ width: WindowWidth > 768 ? '60%' : '', margin: WindowWidth > 768 ? 'auto' : '' }}>{e.comment}</p></div>
                  </div>
                })
                : "" : ""
            }
          </div>
        </center>

        <footer style={{ background: "#000000" }}>

          <div className='d-flex justify-content-center align-items-center py-4'>
            <div className='img-w'>
              <img src="/media/checkout/logo.png" alt="" className='w-100' />
            </div>
            <div className='text-light text-center ms-3
                      '>
              <div className='fw-bold fs-5' style={{ color: "#35b2e4" }}>Knight Rider Tablet</div>
              <small style={{ color: "#747474" }}>THE GOOD MAN SECRET</small>
            </div>
          </div>

          <div className='d-sm-flex d-md-row text-light justify-content-center gap-4'>

            <p>CERTIFICATE</p>
            <p>DISCLAIMER </p>
            <p>REFUND POLICY </p>
            <p>RETURN AND CENCELLATION POLICY </p>
            <p>SHIPPING & DELIVERY POLICY </p>

          </div>

          <div className='text-center text-primary fs-5'>© 2022 Arayurveda | All Rights Reserved.</div>


        </footer>


        {

          WindowWidth < 768 ?

            <div className='text-light w-100 m-auto px-2 rounded-3' id="bicc" style={{ background: "#262525", position: "fixed", bottom: "0%" }}>
              <div className='text-start fs-5'>Knight Rider Tablet</div>

              <div className='d-flex gap-3 my-2 align-items-center' >

                <div className='d-flex align-items-center fs-5' style={{ color: "#fd8d27" }}>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star-half-stroke"></i>
                </div>

                <div className='d-flex align-items-center gap-2'>
                  <div className='fw-bold'>{data.price - data.discount}</div>
                  <strike className="">{data.reguler_price}</strike>
                  <a href='#form' className='text-center text-decoration-none border-0 rounded-3 ms-2 px-3 py-2 bg-success text-light' onClick={() => {

                    setopen(false);

                    // window.location.href = "/thankyou/#form"

                  }}>Buy Now</a>

                </div>

              </div>

            </div>


            : ""


        }





      </div>
    </>
  )
}
