import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';

export default function OrderDilog(props) {

    const navigate = useNavigate();


    const { open, setOpen, Orderid } = props
    const handleClose = () => {
        setOpen(false);
        navigate("/");

    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <center>
                        <img src={"/media/checkout/thankyou.png"} alt="img" width={"50%"} />
                    </center>
                    <center>
                        <h3 className='text-blue fw-bold fs-2'>For Your Purchase</h3>
                        <h6 className='text-blue fw-bold'>Your Order Id : <span className='text-danger' style={{ textDecoration: "underline" }}>#{Orderid}</span></h6>
                        <h6 className="text-info d-flex justify-content-center align-items-center">
                            <InfoIcon /> &nbsp; <span>Please Note Down Your OrderID For Future Reference.</span>
                        </h6>
                        <h6 className="text-success">Your Order Created Successfully</h6>
                        <h5 className="text-dark">Thanks For Being Awesome, <br /> We Hope You Enjoy Your Purchase</h5>
                    </center>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
