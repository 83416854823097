import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { AiOutlineFieldTime, AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import Loader from "./Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useSnackbar } from 'notistack'
// import axios from "axios"

export default function Home() {
  const [searchParams] = useSearchParams();

  const Location = useNavigate();
  // const { enqueueSnackbar: showSnackbar } = useSnackbar();
  // const [Reviews, setReviews] = useState([]);
  // const [AllProducts, setAllProducts] = useState([]);

  if (
    window.location.pathname !== "/thankyou" ||
    window.location.pathname !== " /checkout"
  ) {
    import("./home.css");
  }

  const [Customer_data, setCustomer_data] = useState({
    name: "",
    phone: "",
    address: "",
  });
  const [err, seterr] = useState();
  const [add, setAdd] = useState(52121);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAdd(add + 4);
    }, 2500);
  });

  // const [WIdth, setWIdth] = useState(window.innerWidth)

  // useEffect(() => {
  //     window.addEventListener("resize", () => {
  //         setWIdth(window.innerWidth)
  //     })
  //     // eslint-disable-next-line
  // }, [])

  const PostonClick = async () => {
    let regExp = /[^A-Za-z]/g;
    let letters = Customer_data.name.replace(regExp, " ");
    let RemoveSpce = letters.replace(/  +/g, " ");
    Customer_data.name = RemoveSpce;

    if (
      Customer_data.name === "" ||
      Customer_data.name === "undefined" ||
      !Customer_data.name.length > 0
    ) {
      return seterr("Requiered Name Is Empty");
    }
    seterr();
    if (Customer_data.phone === "" || Customer_data.phone === "undefined") {
      return seterr(" Requiered Mobile Number is Empty");
    }
    seterr();
    if (
      `${Number(Customer_data.phone)}` === "NaN" ||
      Customer_data.phone.length !== 10
    ) {
      return seterr("Mobile Number Is Invelid");
    }
    seterr();
    if (
      Customer_data.phone[0] !== "6" &&
      Customer_data.phone[0] !== "7" &&
      Customer_data.phone[0] !== "8" &&
      Customer_data.phone[0] !== "9"
    ) {
      return seterr("Please Enter Valid Phone Number");
    }
    seterr();
    const formdata = new FormData();

    formdata.append("name", Customer_data.name);
    formdata.append("phone", Customer_data.phone);
    formdata.append("address", Customer_data.address);

    let tracker = searchParams.get("krt");
    if (tracker) {
      formdata.append("krt", tracker);
    }

    try {
      setLoading(true);
      const form = await fetch(`https://x.sxxguru.in/submitdata.php`, {
        body: formdata,
        method: "POST",
      });
      const data = await form.json();
      window.scrollTo(0, 0);
      if (data.ok) {
        let destination = decodeURI(data.destination);
        let name = destination.split("?name=")[1].split("&")[0];
        let phone = destination.split("&phone=")[1].split("&")[0];
        let address = destination.split("&address=")[1].split("&")[0];
        // if (window.conversion_code) {
        //   return gtag_report_conversion(
        //     `/thankyou?name=${name}&phone=${phone}&address=${address}`
        //   );
        // }
        return Location(
          `/thankyou?name=${name}&phone=${phone}&address=${address}`
        );
      }
      Location("/thankyou");
    } catch (err) { }
  };

  // const FetchProduct = async () => {
  //     try {
  //         setLoading(true)
  //         const result = await axios.get(`${process.env.REACT_APP_API_URL}product/fetch_with_api_key?api_key=${process.env.REACT_APP_API_KEY}`, { headers: { "web_site_url": process.env.REACT_APP_URL } })
  //         if (result.status === 200) {
  //             setLoading(false)
  //             document.title = result.data.product[0].name
  //             return setAllProducts(result.data.product)
  //         }
  //         setLoading(false)
  //         showSnackbar("Failed to Fetch Reviews", { variant: "error" })
  //     } catch (err) {
  //         setLoading(false)
  //         return showSnackbar(err.response && err.response.data ? err.response.data.message : err.message, { variant: "error" })
  //     }
  // }

  // const FetchProductReviews = async () => {
  //     let proids = AllProducts.map((x) => x.id)
  //     proids = proids.join(",")
  //     try {
  //         setLoading(true)
  //         let Reviews = await axios.get(`${process.env.REACT_APP_API_URL}reviews/proid`, {
  //             headers: {
  //                 "proid": proids
  //             }
  //         })
  //         setLoading(false)
  //         if (Reviews.status === 200) {
  //             setReviews(Reviews.data.reviews)
  //         }
  //     } catch (err) {
  //         console.log(err)
  //         setLoading(false)
  //         showSnackbar("Reviews Not Found", { variant: "info" })
  //     }
  // }

  // useEffect(() => {
  //     FetchProduct()
  //     // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //     if (AllProducts.length > 0) {
  //         FetchProductReviews()
  //     }
  //     // eslint-disable-next-line
  // }, [AllProducts]);
  return (
    <div>
      {Loading ? <Loader /> : ""}
      <div className="container d-size">
        <div className="imagesA w-100 bg-dark opacity-100">
          <div
            className="d-flex justify-content-evenly align-items-center border opacity-100"
            style={{ height: "6rem" }}
          >
            <div className="fs-3">
              <FiMenu className="icon-size" />
            </div>
            <div className="fs-3">
              <img
                src="/images/logo.png"
                alt="no-found"
                style={{ width: "100%" }}
              />
            </div>
            <div className="fs-3">
              <FaSearch className="icon-size" />
            </div>
          </div>
          <div className=" mt-3">
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              style={{ height: "50vh" }}
            >
              <div
                className="d-flex justify-content-center"
                style={{ position: "relative", top: "-21px" }}
              >
                <p className="me-5 d-flex align-items-center">
                  <AiOutlineFieldTime className="fs-4 me-1 fw-bold" />{" "}
                  <span className="fs-5">{moment().format("DD MMM YYYY")}</span>
                </p>
                <p className="d-flex align-items-center">
                  <AiOutlineEye className="fs-4 me-1 fw-bold" />
                  <span className="fs-5">{add}</span>
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <p className="px-3 my-0 text-center fw-bold fs back-g">
                  ज्यादा देर सेक्स का मजा लेने का 100% नेचुरल उपाय
                </p>
              </div>
            </div>
          </div>
          {/* <div className='d-flex flex-column justify-content-around align-item-center main-div-images'>
                   <div className='d-flex justify-content-center'>
                       <p className='me-5 d-flex align-items-center'><AiOutlineFieldTime className='fs-4 me-1 fw-bold' /> <span className='fs-5'>17.08.2022</span></p>
                       <p className='d-flex align-items-center'><AiOutlineEye className='fs-4 me-1 fw-bold' /><span className='fs-5'>{add}</span></p>
                   </div>
                   <div className='d-flex justify-content-center'>
                       <p className='px-3 text-center fw-bold ' style={{ "fontSize": "2.2rem", "lineHeight": "42px" }}>
                           टाइमिंग बढ़ाकर ज्यादा देर तक सेक्स का मजा लेने का 100% नेचुरल उपाय
                       </p>
                   </div>
                   <div className='text-center container'>
                      
                   </div>
               </div> */}
        </div>
        <div
          className="opacity-100 w-100 text-light pt-1 bg-dark"
          style={{ backgroundColor: "#181616a8" }}
        >
          <div className="container">
            <p className="second-main-div" style={{ textAlign: "left" }}>
              क्या आप भी उन पुरुषों में से है जिन्ही इच्छा एक रोमांचक चुदाई करना
              है पर सेक्स के शुरुआत में ही झड़ जाते है और कुछ ही सेकेंडो में लिंग
              ढीला पड़ जाता है? ये एक बेहद शर्मिंदगी का पल होता है जिसमें आप अपनी
              पार्टनर को सेक्स का मजा नहीं दे पाते और उसकी नजरों में गिर जाते
              है। ऐसे पुरुषों के बार-बार के ऐसे सेक्स प्रदर्शन से उनकी पार्टनर
              संतुष्ट नहीं होती और सेक्स का असल मजा लेने के लिए वो आपको धोखा
              देती है और दूसरा पार्टनर ढूंढ लेती है।
            </p>
            <div className="text-center pb-2">
              <img
                src="/images/image-gif1.gif"
                alt="no images found"
                className="p-image"
              />
            </div>

            <h3
              className="fs-3 fw-bold d-flex align-items-center color-rider rounded-3 mt-3 margining"
              style={{ textAlign: "left", lineHeight: "28px", padding: "6px" }}
            >
              यौन कमजोरी से परेशान पुरुषों को पार्टनर से मिलता है धोखा!
            </h3>

            {/* <div className='image-chart text-center mt-4 row justify-content-center align-items-center'>
                       <div className='col-4 fw-bold fs-5' >Risk of inferlity</div>
                       <div className='col-4'>
                           <div className='d-flex flex-column justify-content-center align-items-center'>
                               <p className='fs-5 fw-bold'>Big Panish</p>
                               <img src='http://india.03aj.top/12/assets/img/Diagram1.svg' />
                           </div>
                       </div>
                       <div className='col-4'>
                           <div className='d-flex flex-column justify-content-center align-items-center'>
                               <p className='fs-5 fw-bold'>Small Panish</p>
                               <img src='http://india.03aj.top/12/assets/img/Diagram2.svg' />
                           </div>
                       </div>
                   </div> */}

            <p className="second-main-div" style={{ textAlign: "left" }}>
              {" "}
              आये दिन पति को धोखा देकर दूसरे पुरुषों के साथ संबंध की खबरे आती
              है। इसका मुख्य वजह है- जिस महिला को अपने पति से भरपूर यौन संतुष्टि
              नहीं मिलती वो सेक्स का बेहतर मजा लेने के लिए दूसरे मर्द तलाशती है
              जो उसे संतुष्ट करे। दोस्तों, सेक्स एक प्राइवेट पर विशेष मुद्दा है
              जिसे अधिकांश पुरुष नज़र अंदाज़ करते है। इसे नजर अंदाज करना सही बात
              नहीं है ये आपकी लाइफ बर्बाद कर सकता है।
            </p>

            <div className="text-center">
              <img
                src="/images/image3.jpg"
                alt="noimagefound"
                className="p-image"
              />
            </div>

            <p className="second-main-div" style={{ textAlign: "left" }}>
              उदहारण के तौर पर- मुंबई की एक महिला जो अपने पति से सतुंष्ट नहीं
              थी। वह पड़ोस के एक कुवारें लड़के के संपर्क में आ गयी और उसके साथ
              चुदाई का मजा लेने लगी। धीरे-धीरे ये बात उसके पति को पता चली और वो
              गुस्से में आकर उस लड़के और अपनी पत्नी दोनों को मार दिया। पुलिस ने
              उसे पकड़ लिया और वह सलाखों के पीछे है। ऐसी बहुत सारी घटनाये आपको
              खबरों में सुनने को मिल जायेंगी जिसका कारण ख़राब यौन प्रदर्शन होता
              है और उनकी लाइफ बर्बाद हो जाती है।
            </p>

            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              (आंकड़ों के अनुसार हर 3 में से 2 पुरुष जल्दी झड़ जाते है और चुदाई का
              असली मजा नहीं ले पाते है।)
            </p>

            {/* <div className='col-12 my-3 bg-black'>
                       <div className='row justify-content-center align-items-center'>
                           <div className="col-12 col-md-6 order-2 order-md-2">
                               <p className='fs-4'> एक छोटा लिंग बहुत शर्म की बात होती है।आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है
                                   एक छोटा लिंग बहुत शर्म की बात होती है।आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है
                                   एक छोटा लिंग बहुत शर्म की बात होती है।आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है</p>
                           </div>
                           <div className="col-12 col-md-6 order-1 order-md-1">
                               <img src='http://india.03aj.top/12/assets/img/Image5.jpg' alt="no found" style={{ "width": "100%" }} />
                           </div>
                       </div>
                   </div> */}

            <h3
              className="fs-3 fw-bold d-flex align-items-center color-rider rounded-3 mt-3 margining"
              style={{ textAlign: "left", lineHeight: "28px", padding: "6px" }}
            >
              अधिकतर पुरुष अपनाते है गलत उपाय जो समस्या और बढ़ा देता है!
            </h3>
            <p
              style={{ textAlign: "left", fontSize: "1.1rem" }}
              className="second-main-div"
            >
              {" "}
              अधिकतर पुरुष मार्केट में हर मेडिकल पर उपलब्ध नीली गोली का इस्तेमाल
              करते है जो स्वास्थ्य के लिए अत्यंत नुकसानदायक होता है। हार्ट
              ब्लॉकेज खोलने की यह दवाई जिसके साइड इफ़ेक्ट से लिंग में तनाव मिलता
              है और समस्या पर कोई असर नहीं पड़ता है। इसके ज्यादा इस्तेमाल से लिंग
              में तेज दर्द, सूजन के साथ-साथ लिवर, किडनी, व हार्ट संबंधित
              समस्याएं उत्पन्न होती है। कभी-कभी लिंग में ब्लड सर्कुलशन इतना
              ज्यादा हो जाता है जिससे नस डैमेज हो सकती है और आप सदा के लिए यौन
              क्रिया से वंचित रह सकते है।
            </p>

            <div className="text-center">
              <img
                src="/images/image8.jpg"
                alt="noimagefound"
                className="p-image"
              />
            </div>

            <p
              className="fs-3 fw-bold d-flex align-items-center color-rider rounded-3 mt-4 margining"
              style={{ textAlign: "left", lineHeight: "28px", padding: "6px" }}
            >
              सेक्स का भरपूर मजा लेने के लिए अपनाये #1आयुर्वेदिक फार्मूला!
            </p>
            <p
              style={{ textAlign: "left", fontSize: "1.1rem" }}
              className="second-main-div"
            >
              आयुर्वेद में एक से बढ़कर एक जड़ीबूटियों का जिक्र किया गया है जिसके
              इस्तेमाल से आप धुआँधार चुदाई कर अपनी पार्टनर को रूहानी संतुष्टि दे
              सकते है। इन पावरफुल जड़ीबूटियों में अश्वगंधा, शिलाजीत, अकरकरा,
              केसर, सफ़ेद मूसली, शतावरी, अभ्रक भस्म, वंग भस्म, और गोक्षुरा का नाम
              शीर्ष पर आता है। इनके उचित संयोजन से बना है{" "}
              <span className="text-danger fw-bold">"Knight Rider Tablet"</span>{" "}
              जो शुद्ध आयुर्वेदिक और बेहद असरदार फार्मूला है। आज मार्केट में कई
              तरह के प्रोडक्ट उपलब्ध हैं लेकिन प्रयोगशालाओं में किए गए शोधों और
              मेडिकल प्रैक्टिस की आधार पर इनमें से सबसे असरदार नुस्खा है
              <span className="text-danger fw-bold">
                &nbsp;"Knight Rider Tablet"
              </span>
              . इसकी क्लीनिकल रिसर्च पर 3 साल से भी ज़्यादा समय लगाया गया हैं।
              <span className="text-danger fw-bold">
                &nbsp;"Knight Rider Tablet"
              </span>{" "}
              ने सभी आवश्यक सुरक्षा और कार्यक्षमता प्रमाणपत्र हासिल कर लिए हैं।
              यह भारत सरकार आयुष मंत्रालय (स्वास्थ्य विभाग) द्वारा प्रमाणित
              उत्पाद है।
            </p>

            <div className="text-center">
              <img
                src="/images/image7.jpg"
                alt="noimagefound"
                className="p-image"
              />
            </div>

            <h3
              className="fs-3 fw-bold d-flex align-items-center color-rider rounded-3 mt-4 margining"
              style={{ textAlign: "left", lineHeight: "28px", padding: "6px" }}
            >
              अपनी पार्टनर को सेक्स का वह मजा दें जो वह चाहती है
            </h3>

            {/* <BsFillStarFill className='fs-2 text-dark d-md-inline d-none d-sm-none' /> */}

            {/* <div className='d-flex justify-content-start align-items-start'>
                       <div className='skull'>  <span className='span-style'>1</span></div>
                       <p className='fs-5'>  छोटा लिंग बहुत शर्म की बात होती है।एक छोटा लिंग बहुत शर्म की बात होती है।एक छोटा लिंग बहुत शर्म की बात होती है।र्म की बात होती है।आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है
                       </p>
                   </div> */}
            {/* <div className='d-flex justify-content-start align-items-start'>
                       <div className='skull'> <span className='span-style'>2</span></div>
                       <p className='fs-5'>त होती है।एक छोटा लिंग बहुत शर्म की बात होती है।एक छोटा लिंग बहुत शर्म की बात होती है।एक छोटा लिंग बहुत शर्म की बात होती है।एक छोटा लिंग बहुत शर्म की बात होती है।र्म की बात होती है।आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है
                       </p>
                   </div> */}

            {/* <div className='d-flex justify-content-start align-items-start'>
                       <div className='skull'> <span className='span-style'>3</span></div>
                       <p className='fs-5'>आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है
                       </p>
                   </div> */}
            {/* <div className='d-flex justify-content-start align-items-start'>
                       <div className='skull'> <span className='span-style'>4</span></div>
                       <p className='fs-5'>लिंग बहुत शर्म की बात होती है।र्म की बात होती है।आज नम्रदी आधुनिक मर्द की सबसे बड़ी समस्या है
                       </p>
                   </div> */}

            <p
              style={{ textAlign: "left", fontSize: "1.1rem" }}
              className="second-main-div"
            >
              <span className="text-danger fw-bold">
                {" "}
                "Knight Rider Tablet"
              </span>{" "}
              की मदद से लंड में जबरदस्त उफान आता है जो घंटो तक ढ़ीला नहीं पड़ता
              है। इसके एक निश्चित समय तक सेवन से आप अपने पार्टनर को चुदाई का वह
              मजा दे सकते है जो वह आपसे चाहती है। आपकी पार्टनर 4 बार झड़ जाएगी
              फिर भी आपका लिंग ढ़ीला नहीं पड़ेगा।
            </p>

            <div className="text-center">
              <img
                src="/images/image6.gif"
                alt="noimagefound"
                className="p-image"
              />
            </div>

            <h3
              className="fs-3 fw-bold d-flex align-items-center color-rider rounded-3 mt-4 margining"
              style={{ textAlign: "left", lineHeight: "28px", padding: "6px" }}
            >
              "Knight Rider Tablet" के इस्तेमाल से निम्न फायदे होते है।{" "}
            </h3>
            <div className="d-flex justify-content-start align-items-baseline margining">
              <div className="skull">
                {" "}
                <span className="span-style">1</span>
              </div>
              <p className="fs-5 text-start" style={{ margin: "8px 0" }}>
                {" "}
                घंटो चोदने वाली स्टैमिना और एनर्जी मिलती है।{" "}
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-baseline margining">
              <div className="skull">
                {" "}
                <span className="span-style">2</span>
              </div>
              <p className="fs-5 text-start" style={{ margin: "8px 0" }}>
                सेक्स टाइमिंग में मनचाही बृद्धि होती है{" "}
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-baseline margining">
              <div className="skull">
                {" "}
                <span className="span-style">3</span>
              </div>
              <p className="fs-5 text-start" style={{ margin: "8px 0" }}>
                {" "}
                लंड में जबरदस्त उफान और रॉड जैसी कड़कपन{" "}
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-baseline margining">
              <div className="skull">
                {" "}
                <span className="span-style">4</span>
              </div>
              <p className="fs-5 text-start" style={{ margin: "8px 0" }}>
                {" "}
                जबरदस्त जोश और उत्तेजना मिलती है{" "}
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-baseline margining">
              <div className="skull">
                {" "}
                <span className="span-style">5</span>
              </div>
              <p className="fs-5 text-start" style={{ margin: "8px 0" }}>
                शुक्राणुओं की संख्या बढ़ती है{" "}
              </p>
            </div>

            <div className="d-flex justify-content-start align-items-baseline margining">
              <div className="skull">
                {" "}
                <span className="span-style">6</span>
              </div>
              <p className="fs-5 text-start" style={{ margin: "8px 0" }}>
                धातु रोग तथा दूसरी यौन समस्या से राहत मिलती है।{" "}
              </p>
            </div>

            <div className="row text-center align-items-center from-adding border-2 bg-dark text-dark">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img
                      src="/images/image5.jpg"
                      alt="no found"
                      className="p-image"
                    />
                  </div>
                  <div className="col-12 d-flex flex-column bg-dark">
                    <p
                      style={{ fontSize: "15px", marginTop: "11px" }}
                      className="text-light  fw-bold"
                    >
                      * प्रोडक्ट संबंधित जानकारी हेतु हमारे ग्राहक सेवा अधिकारी
                      से बात करने के लिए फॉर्म में नाम, पता और नंबर भरकर सब्मिट
                      करें !
                    </p>
                    <div className="text-center div-style">
                      {/* <p style={{ "fontSize": "11px", "marginTop": "0" }} className="text-primary"> 30,000 मे से नंबर 29,982 26.08.2022</p> */}
                      {/* <div className='border border-2 border-primary w-100 text-center'>
                                       <p style={{ "fontSize": "15px", "marginTop": "11px" }} className="text-dark fw-bold">Mojo care पाने के लिए अपना नाम या फोन नंबर
                                           दिए खाने में भरे और ऑर्डर भेजे पर क्लिक करदे</p>
                                   </div> */}

                      <div className="form-image pt-2 text-center">
                        <img
                          src="/images/doctor-logo.png"
                          alt="no found"
                          className="p-image"
                          style={{ width: "100%", textAlign: "center" }}
                        />
                      </div>

                      <h4
                        className="fw-bold fs-2 my-2 color-add"
                        style={{ color: "maroon" }}
                      >
                        Official Inquiry Form
                      </h4>
                      <form>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label fw-bold fs-4"
                            style={{ color: "maroon" }}
                          >
                            Name ( नाम )
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength={30}
                            placeholder="नाम"
                            required
                            onChange={(e) =>
                              setCustomer_data({
                                ...Customer_data,
                                name: e.target.value,
                              })
                            }
                          />
                          {err && err.includes("Name") ? (
                            <span className="text-light">{err}</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputPassword1"
                            style={{ color: "maroon" }}
                            className="form-label fw-bold fs-4"
                          >
                            Phone No ( फोन नंबर )
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text border border-2 border-dark fw-bold fs-5"
                              id="inputGroup-sizing-md"
                            >
                              +91
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="फ़ोन नंबर"
                              onChange={(e) =>
                                setCustomer_data({
                                  ...Customer_data,
                                  phone: e.target.value,
                                })
                              }
                              maxLength={10}
                            />
                          </div>
                          {err && err.includes("Number") ? (
                            <span className="text-light">{err}</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label fw-bold fs-4"
                            style={{ color: "maroon" }}
                          >
                            Address ( पता )
                          </label>
                          <textarea
                            className="form-control textarea-form"
                            placeholder="आपका पूरा पता दर्ज करे"
                            rows={3}
                            onChange={(e) =>
                              setCustomer_data({
                                ...Customer_data,
                                address: e.target.value,
                              })
                            }
                          />
                        </div>
                        <button
                          type="button"
                          onClick={PostonClick}
                          className="btn button-order mb-3"
                        >
                          सब्मिट करें
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-3">
              <div className="container">
                <div className="row text-center justify-content-center bordering scrolling px-3">
                  <h1 className="border-bottom border-2 border-light py-2">
                    Reviews
                  </h1>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Satyendra, 28 Year (U.P)
                          </p>
                          <p className="second-main-div-p text-start">
                            सच कहूँ तो मैं पुरुष समस्या से काफी दिनों से परेशान
                            था और कई प्रोडक्ट आजमा चुका था पर कोई खास फायदा नहीं
                            मिला। पर इस आयुर्वेदिक प्रोडक्ट ने तो कमाल कर दिया।
                            मेरी सभी पुरुष कमजोरी अब ख़त्म हो गयी है अब मैं अपने
                            लाइफ को भरपूर एन्जॉय कर रहा हूँ।
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Amit Singh, 39 Year (Ranchi)
                          </p>
                          <p className="second-main-div-p text-start">
                            This is the best and completely safe to use.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Diwakar, 32 Year (M.P)
                          </p>
                          <p className="second-main-div-p">
                            Mujhe iska jabardast fayda mila. Shauru me kuchh
                            dino tak lga ki ye bhi other product jaise useless
                            hai par end of the month maine shandar badlav dekha.
                            iski packeging safe aur delivery facility bhi bahut
                            fast hai. overall yeh bahut hi behatrin product
                            hai....highly recommended for men.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Suraj, 35 Year (M.H)
                          </p>
                          <p className="second-main-div-p">
                            Pichle Kuch mahino se main weakness feel kar rha tha
                            kuch hi munute me thak jaata tha then my friend
                            recommend me knight rider tablet. Iski madad se meri
                            shadishuda life me fir se bahar aagyi. thanks a lot
                            to knight rider🙏🙏
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Jeevan Panda, 42 Year (Bihar)
                          </p>
                          <p className="second-main-div-p">
                            This is very effective! I have used it for some time
                            and feel very energized and lasts longer in bed.
                            Most Important it has no side effects, and it is
                            fully safe to use.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Satyam Jha, 36 Year (Pune)
                          </p>
                          <p className="second-main-div-p">
                            अब तक मैंने जितनी दवाइयां इस्तेमाल किया यह उनसे
                            बेहतर असर करता है।
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-3 border-knight">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 col-md-3 col-lg-2  second-div-images">
                        <img
                          style={{
                            width: "7rem",
                            borderRadius: "50%",
                            boxShadow: "9px 9px 17px black",
                          }}
                          alt="no found"
                          src="/images/user.png"
                        />
                      </div>
                      <div className="col-12 col-md-9 col-lg-10">
                        <div className="d-flex flex-column justify-content-start border-image-all py-2">
                          <p className="second-main-div-image">
                            Junaid, 30 Year (Gujrat)
                          </p>
                          <p className="second-main-div-p">
                            best product for male wellness, salo se kra rahi
                            samsya ka ilaj 2 mahine ke andar ho gya.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <p className="text-primary text-center fs-5 mb-0 fw-bold">
                  &#169; 2022 COPYRIGHT. ALL RIGHTS RESERVED
                </p>
                <p className="text-light text-center fs-5 my-2 fw-bold">
                  PRIVACY POLICY | TERMS & CONDITION
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
